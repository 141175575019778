import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { ConfirmorderPage } from '../confirmorder/confirmorder.page';
import { SigninPage } from '../signin/signin.page';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.page.html',
  styleUrls: ['./cart.page.scss'],
})
export class CartPage implements OnInit {
  cart:any = {items:[]};
  loaded = false;
  token = '';

  constructor(public http: HttpClient, public alertCtrl: AlertController, public router: Router, public modalController:ModalController) {
  }

  back(){
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  ionViewWillEnter() {
    this.cart = [];
    this.loaded = false;
    if(localStorage.getItem('token')){
      this.token = localStorage.getItem('token');
      this.get_cart();
    }
  }

  async goLogin(){
    await(await this.modalController.create({component:SigninPage})).present();
  }

  get_cart() {
    this.http.post<any>('https://api.blowjob.cherish.com.ua/api/get_cart', {
      token: this.token
    }).subscribe(async res => {
      if (res.error_code == 0) {
        this.cart = res.data.cart;
        this.loaded = true;
        this.calc_cart();
      } else {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Некорректный токен', buttons: ["OK"] }))).present();
      }
    }, async err => {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Проверьте интернет-соединение', buttons: ["OK"] }))).present();
    })
  }

  ngOnInit() {
  }

  change_amount(id, amount) {
    this.http.post<any>('https://api.blowjob.cherish.com.ua/api/change_amount', {
      token: this.token,
      id: id,
      amount:amount
    }).subscribe(async res => {
      if (res.error_code == 0) {
        this.calc_cart();
      } else {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Некорректный токен', buttons: ["OK"] }))).present();
      }
    }, async err => {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Проверьте интернет-соединение', buttons: ["OK"] }))).present();
    })
  }

  inc(item) {
    item.amount++;
    this.change_amount(item.id, item.amount);
  }

  async dec(item) {
    if (item.amount > 1) {
      item.amount--;
      this.change_amount(item.id, item.amount);
    } else {
      await(await(
        this.alertCtrl.create({header:'Вы хотите удалить товар?',buttons:[{
          text:"Нет"
        }, {
          text: 'Да',
          handler:() => {this.remove(item);}
        }]})
      )).present();
    }
  }

  async calc_cart(){
    this.cart.sum = 0;
    console.log(this.cart.items);
    await this.cart.items.forEach(async item => {

      if(!item.main_price){
        item.main_price = item.product_info.price;
      } else {
        item.product_info.price = item.main_price;
      }

      if(item.option_id > 0){
        await item.product_info.option.values.forEach(async (option) => {
          if(option.id == item.option_id){
            item.product_info.price = option.price;
            item.selected_option = option;
          }
        })
      }

      this.cart.sum += item.amount * item.product_info.price;

      await item.adds.forEach(async item_add => {
        this.cart.sum += item.amount * item_add.price;
        item.product_info.price = Number(item.product_info.price) + Number(item_add.price);
      });
    });
  }

  remove(item){
    this.http.post<any>('https://api.blowjob.cherish.com.ua/api/remove_cart', {
      token: this.token,
      id: item.id
    }).subscribe(async res => {
      if (res.error_code == 0) {
        await this.cart.items.forEach((cart_item, index) => {
          if(item.id == cart_item.id){
            this.cart.items.splice(index, 1);
          }
        });
        this.calc_cart();
      } else {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Некорректный токен', buttons: ["OK"] }))).present();
      }
    }, async err => {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Проверьте интернет-соединение', buttons: ["OK"] }))).present();
    })
  }

  async go_confirm(){
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: ConfirmorderPage,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }


}