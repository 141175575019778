import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.page.html',
  styleUrls: ['./sms.page.scss'],
})
export class SmsPage implements OnInit {
  phone: string = '';
  sms_code: string = '';
  logo = '../../assets/logo.png';

  constructor(public http: HttpClient, public alertCtrl: AlertController, public router:Router, public modalController:ModalController) {
    this.phone = localStorage.getItem('phone');
  }

  ngOnInit() {
  }

  async back(){
    this.modalController.dismiss();
  }
  
  async confirm() {
    if (this.phone == '' || this.sms_code == '') {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Заполните все поля', buttons: ["OK"] }))).present();
      return;
    }

    this.http.post<any>('https://api.blowjob.cherish.com.ua/api/confirm_sms', {
      sms_code: this.sms_code,
      phone: this.phone,
    }).subscribe(async res => {
      if (res.error_code == 0) {
        localStorage.setItem('token', res.data.user.token);
        localStorage.setItem('data', res.data.user);
        location.reload();
      } else {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Неправильный смс код', buttons: ["OK"] }))).present();
      }
    }, async err => {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Проверьте интернет-соединение', buttons: ["OK"] }))).present();
    })
  }


}
