import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { SigninPage } from '../signin/signin.page';
import { SmsPage } from '../sms/sms.page';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
})
export class SignupPage implements OnInit {

  first_name = 'Максим';
  last_name = '';
  phone = '0963617158';
  password = '1111';
  logo = '../../assets/logo.png';

  constructor(public http: HttpClient, public alertCtrl: AlertController, public router:Router, public modalController:ModalController) { }

  ngOnInit() {
  }

  async back(){
    this.modalController.dismiss();
    await(await this.modalController.create({component:SigninPage})).present();
  }

  async signup() {
    if (this.phone == '' || this.password == '' || this.first_name == '') {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Заполните все поля', buttons: ["OK"] }))).present();
      return;
    }

    this.http.post<any>('https://api.blowjob.cherish.com.ua/api/signup', {
      first_name: this.first_name,
      last_name: this.last_name,
      phone: this.phone,
      password: this.password
    }).subscribe(async res => {
      if (res.error_code == 0) {
        localStorage.setItem('phone', this.phone);
        await(await this.modalController.create({component:SmsPage})).present();
      } else {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Телефон уже используется', buttons: ["OK"] }))).present();
      }
    }, async err => {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Проверьте интернет-соединение', buttons: ["OK"] }))).present();
    })
  }

}
