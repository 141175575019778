import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { ForgotPage } from '../forgot/forgot.page';
import { SignupPage } from '../signup/signup.page';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.page.html',
  styleUrls: ['./signin.page.scss'],
})
export class SigninPage implements OnInit {

  phone = '0980971283';
  password = '1111';
  logo = '../../assets/logo.png';

  constructor(public http: HttpClient, public alertCtrl: AlertController, public router:Router, public modalController:ModalController) { }

  ngOnInit() {
  }

  back(){
    this.modalController.dismiss();
  }

  async go_reg(){
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: SignupPage,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

  async go_forgot(){
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: ForgotPage,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }


  async signin() {
    if (this.phone == '' || this.password == '') {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Заполните все поля', buttons: ["OK"] }))).present();
      return;
    }

    this.http.post<any>('https://api.blowjob.cherish.com.ua/api/signin', {
      phone: this.phone,
      password: this.password
    }).subscribe(async res => {
      if (res.error_code == 0) {
        localStorage.setItem('token', res.data.user.token);
        localStorage.setItem('data', res.data.user);
        location.reload();
      } else {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Пользователь не найден', buttons: ["OK"] }))).present();
      }
    }, async err => {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Проверьте интернет-соединение', buttons: ["OK"] }))).present();
    })
  }

}
