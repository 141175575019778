import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { SigninPage } from '../signin/signin.page';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.page.html',
  styleUrls: ['./forgot.page.scss'],
})
export class ForgotPage implements OnInit {

  phone = '';

  constructor(public http: HttpClient, public alertCtrl: AlertController, public router:Router, public modalController:ModalController) {
  }

  ngOnInit() {
  }


  async back(){
    this.modalController.dismiss();
    await(await this.modalController.create({component:SigninPage})).present();
  }

  async confirm() {
    if (this.phone == '') {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Заполните все поля', buttons: ["OK"] }))).present();
      return;
    }

    this.http.post<any>('https://api.blowjob.cherish.com.ua/api/forgot_password', {
      phone: this.phone,
    }).subscribe(async res => {
      if (res.error_code == 0) {
        await (await (this.alertCtrl.create({ header: 'Пароль сброшен', message: 'На номер телефона '+this.phone+' выслан новый пароль. Рекомендуем его сменить после первой авторизации', buttons: ["OK"] }))).present();
        this.back();
      } else {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Телефон не найден', buttons: ["OK"] }))).present();
      }
    }, async err => {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Проверьте интернет-соединение', buttons: ["OK"] }))).present();
    })
  }
  

}
