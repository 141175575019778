import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CartPage } from './cart/cart.page';
import { SigninPage } from './signin/signin.page';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  text = 'texxzczxc';

  constructor(public router: Router, public translate: TranslateService, public modalController: ModalController) {

    if (localStorage.getItem('lang')) {
      translate.setDefaultLang(localStorage.getItem('lang'));
    } else {
      localStorage.setItem('lang', 'ru');
      translate.setDefaultLang('ru');
    }

    if (localStorage.getItem('first')) {
      this.router.navigateByUrl('/home', { replaceUrl: true });
    } else {
      this.router.navigateByUrl('/slider', { replaceUrl: true });
    }
  }

  async openCart() {
    if (localStorage.getItem('token')) {
      this.openModal(CartPage);
    } else {
      this.openModal(SigninPage);
    }
  }

  async openModal(component){
    const modal = await this.modalController.create({
      component: component,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }
}
