import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { CartPage } from '../cart/cart.page';

@Component({
  selector: 'app-confirmorder',
  templateUrl: './confirmorder.page.html',
  styleUrls: ['./confirmorder.page.scss'],
})
export class ConfirmorderPage implements OnInit {
  user: any = { bonus: 0, discount: 0 };
  cart: any = {};
  addresses_self = [];
  addresses = [];
  token = '';
  promocode = '';
  promocode_discount = 0;
  order_sum = 0;

  price = {
    order: 0,
    promo_discount: 0,
    discount: 0,
    bonus: 0,
    result: 0,
    delivery: 0
  }

  order = {
    delivery_type: 0,
    address_id: 0,
    address_self_id: 0,
    pay_type: 0,
    comment: ''
  }

  constructor(public http: HttpClient, public alertCtrl: AlertController, public router: Router, public navCtrl: NavController, public ngZone: NgZone, public modalController: ModalController) {
    this.token = localStorage.getItem('token');
    this.get_addresses();
    this.get_addresses_selfservice();
    this.get_cart();
  }

  ionViewDidEnter() {
    this.get_addresses();
  }

  get_profile() {
    this.http.post<any>('https://api.blowjob.cherish.com.ua/api/get_profile', {
      token: this.token
    }).subscribe(async res => {
      if (res.error_code == 0) {
        this.user = res.data.user;
        this.generate_price_sum();
      } else {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Некорректный токен', buttons: ["OK"] }))).present();
      }
    }, async err => {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Проверьте интернет-соединение', buttons: ["OK"] }))).present();
    })
  }

  async update_delivery_type(value) {
    if (this.order.delivery_type == 0) {
      this.price.delivery = 0;
      this.order.address_self_id = 0;
    } else {
      this.order.address_id = 0;
    }

    this.calc_cart();
  }

  async update_delivery_price(value) {
    await this.addresses.forEach(async address => {
      if (address.id == this.order.address_id) {
        if (this.price.order - this.price.bonus - this.price.discount - this.price.promo_discount >= address.free) {
          this.price.delivery = 0;
        } else {
          this.price.delivery = Number(address.region.price);
        }
      }
    });

    this.calc_cart();
  }

  async back() {
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: CartPage,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

  get_cart() {
    this.http.post<any>('https://api.blowjob.cherish.com.ua/api/get_cart', {
      token: this.token
    }).subscribe(async res => {
      if (res.error_code == 0) {
        this.cart = res.data.cart;
        this.get_profile();
      } else {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Некорректный токен', buttons: ["OK"] }))).present();
      }
    }, async err => {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Проверьте интернет-соединение', buttons: ["OK"] }))).present();
    })
  }

  async use_bonus(ev) {
    console.log(ev);
    await this.ngZone.run(() => {
      if (this.price.bonus > this.user.balance) {
        this.price.bonus = this.user.balance;
        console.log(this.price.bonus);
      }
    });

    this.calc_cart();
  }

  async generate_price_sum() {
    this.price.order = 0;
    console.log(this.cart.items);
    await this.cart.items.forEach(async item => {

      if (!item.main_price) {
        item.main_price = item.product_info.price;
      } else {
        item.product_info.price = item.main_price;
      }

      if (item.option_id > 0) {
        await item.product_info.option.values.forEach(async (option) => {
          if (option.id == item.option_id) {
            item.product_info.price = option.price;
            item.selected_option = option;
          }
        })
      }

      this.price.order += item.amount * item.product_info.price;

      await item.adds.forEach(async item_add => {
        this.price.order += item.amount * item_add.price;
        item.product_info.price = Number(item.product_info.price) + Number(item_add.price);
      });
    });

    this.calc_cart();
  }

  async calc_cart() {
    this.price.discount = Math.floor(this.price.order / 100 * this.user.discount);
    this.price.result = this.price.order - this.price.promo_discount - this.price.discount - this.price.bonus + this.price.delivery;
  }

  get_addresses_selfservice() {
    this.http.post<any>('https://api.blowjob.cherish.com.ua/api/get_addresses_selfservice', {
      token: this.token
    }).subscribe(async res => {
      if (res.error_code == 0) {
        this.addresses_self = res.data.addresses;
        if (this.addresses_self.length > 0) {
          this.order.address_self_id = this.addresses_self[0].id;
        } else {
          this.order.delivery_type = 1;
        }
      } else {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Некорректный токен', buttons: ["OK"] }))).present();
      }
    }, async err => {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Проверьте интернет-соединение', buttons: ["OK"] }))).present();
    })
  }

  get_addresses() {
    this.http.post<any>('https://api.blowjob.cherish.com.ua/api/get_addresses', {
      token: this.token
    }).subscribe(async res => {
      if (res.error_code == 0) {
        this.addresses = res.data.addresses;
        if (this.addresses.length > 0) {
          this.order.address_id = this.addresses[0].id;
        }
      } else {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Некорректный токен', buttons: ["OK"] }))).present();
      }
    }, async err => {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Проверьте интернет-соединение', buttons: ["OK"] }))).present();
    })
  }

  ngOnInit() {
  }

  async confirm_order() {

    if (this.order.delivery_type == 0 && this.order.address_self_id == 0) {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Выберите точку самовывоза', buttons: ["OK"] }))).present(); return;
    }

    if (this.order.delivery_type == 1 && this.order.address_id == 0) {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Выберите адрес доставки', buttons: ["OK"] }))).present(); return;
    }

    this.http.post<any>('https://api.blowjob.cherish.com.ua/api/confirm_order', {
      token: this.token,
      delivery_type: this.order.delivery_type,
      address_id: this.order.address_id,
      address_self_id: this.order.address_self_id,
      pay_type: this.order.pay_type,
      comment: this.order.comment,
      promocode: this.promocode,
      delivery_price: this.price.delivery,
      bonus: this.price.bonus
    }).subscribe(async res => {
      if (res.error_code == 0) {
        await (await (this.alertCtrl.create({ header: 'Заказ успешно оформлен', message: 'В течении 15 минут с Вами свяжется наш менеджер для подтверждения заказа', buttons: ["OK"] }))).present();
        this.modalController.dismiss();
      } else {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Некорректный токен', buttons: ["OK"] }))).present();
      }
    }, async err => {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Проверьте интернет-соединение', buttons: ["OK"] }))).present();
    })
  }

  check_promocode() {
    this.http.post<any>('https://api.blowjob.cherish.com.ua/api/check_promocode', {
      token: this.token,
      promocode: this.promocode
    }).subscribe(async res => {
      if (res.error_code == 0) {
        let promocode = res.data.promocode;
        if (promocode.sum > 0) {
          this.price.promo_discount = promocode.sum;
        } else {
          this.price.promo_discount = Math.floor((this.price.order / 100) * promocode.percent);
        }

        this.calc_cart();

      } else if (res.error_code == 1) {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Промокод не найден', buttons: ["OK"] }))).present();
      } else if (res.error_code == 2) {
        await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Лимит исчерпан', buttons: ["OK"] }))).present();
      }
    }, async err => {
      await (await (this.alertCtrl.create({ header: 'Ошибка', message: 'Проверьте интернет-соединение', buttons: ["OK"] }))).present();
    })
  }

}
